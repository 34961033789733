const FEATURE_HELP_URLS = {
  agent_bots: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725335823-agente-inteligente',
  agents: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725312457-adicionar-agente',
  audit_logs: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725146947-re',
  campaigns: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725335981-campanhas',
  canned_responses: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725147584-atalhos-respostas-rapidas',
  channel_email: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725336066-canal-email',
  channel_facebook: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725336228-canal-facebook-instagram',
  custom_attributes: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1687298598-c',
  dashboard_apps: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725304527-a',
  help_center: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725336477-centro-de-ajuda',
  inboxes: 'https://chwt.app/hc/inboxes',
  integrations: 'https://chat.netfree.com.br/hc/plataforma-netfree/pt_BR/categories/aplicativos-e-integracoes',
  labels: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1710970154-marcadores-ou-etiquetas-de-filtro',
  macros: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725148854-macros',
  message_reply_to: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725336621-responder-a-uma-mensagem',
  reports: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725336771-relatorios',
  sla: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725334359-sla',
  team_management: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725313055-times-de-atendimento',
  webhook: 'https://chat.netfree.com.br/hc/plataforma-netfree/articles/1725300883-w',
};

export function getHelpUrlForFeature(featureName) {
  return FEATURE_HELP_URLS[featureName];
}
